<div class="element" #mainElement>
    <span [ngClass]="[getTypeSend()]">{{ Currency[element.currency]?.symbol}}</span>
    <ul class="name">
        <li><p>{{ getReceiverName(element.receiverFirstName, element.receiverLastname) }}</p> <span class="favorite" *ngIf="element.isFavorite"><img src="/assets/images/star_black.svg" alt=""></span></li>
        <li><p>${{ element.requestedAmount | number: '1.2-2' }}</p></li>
    </ul>
    <ul class="data">
        <li><p>{{ element.receiverAccount | accountMask: element.typeSendReceptor }}</p></li>
        <li><span class="font-size-10 flexo-demi">{{ element.status }}</span></li>
    </ul>
    <div class="button">
        <bcp-button shape="rectangle" tier="secondary" size="md" full-width="true" (click)="sendData(element)">Editar y enviar</bcp-button>
    </div>
  </div>
