import { Component, HostListener, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DataService } from './core/services/data.service';
import { OPERATIONS, UpdateDataService } from './core/interfaces';
import { UtilService } from './core/services/util.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { AuthStatus, PATHS } from './core/constants';
import { AuthService } from './core/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private gtmService = inject(GoogleTagManagerService);
  private router = inject(Router);
  private dataService = inject(DataService);
  private utilService = inject(UtilService);
  private localStorageService = inject(LocalStorageService);
  private authService = inject(AuthService);

  showModalTimeSession = false;
  timeSessionInternval = null;
  showModalTimerSession = false;
  leftTime = null;
  timerInterval;

  constructor() {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
  });
  }

  ngOnInit(): void {
    if (this.isEmbeddedInFacebookOrInstagram()) {
      this.redirectToDefaultBrowser();
    }

    if (sessionStorage.getItem('session_time')) {
      this.intervalModalTimeSession();
    }

    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {

        if (data?.operation === OPERATIONS.SESSION_TIME_LIMIT) {
          this.timeSessionInternval && clearInterval(this.timeSessionInternval)
          sessionStorage.setItem('session_time', (new Date().getTime() + +data.data * 1000).toString());
          this.intervalModalTimeSession();
        }
        if (data?.operation === OPERATIONS.ADD_TIME_LIMIT_SESSION) {

          if (data.data.status === 'disabled') clearInterval(this.timeSessionInternval)
          else {
            clearInterval(this.timeSessionInternval)
            const sessionTime = sessionStorage.getItem('session_time');
            sessionStorage.setItem('session_time', (+sessionTime + (+data.data.time)).toString());
            this.intervalModalTimeSession();
          }
        }
        if (data?.operation === OPERATIONS.SESSION_VALIDATE_TIMER) {
          const  { expire, startDate } =  data.data;
          this.showModalTimerSession = true;
          sessionStorage.setItem('timer_session_started', startDate);
          sessionStorage.setItem('timer_session_expire', expire);
          this.validateTimerSession({ expire, startDate });
        }
      }
    });

  }

  redirectToDefaultBrowser(): void {
    const url = window.location.href;
    if (/android/i.test(navigator.userAgent)) {
      window.location.href = `intent://${url.replace('https://', '')}#Intent;scheme=https;package=com.android.chrome;end;`;
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.open(url, '_blank');
    }
  }

  isEmbeddedInFacebookOrInstagram(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isFacebook = (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1);
    const isInstagram = userAgent.indexOf('Instagram') > -1;
    return isFacebook || isInstagram;
  }


  intervalModalTimeSession(): void {
    this.timeSessionInternval = setInterval(() => {
      const time = +sessionStorage.getItem('session_time') || 0;
      if (!isNaN(time) && time === 0) {
        clearInterval(this.timeSessionInternval);
      }
      const expired = this.utilService.sessionTimeoutLimitExpired(time);
      if (expired) {
        this.localStorageService.removeAllStorage();
        if (this.authService.checkAuthStatus() === AuthStatus.authenticated) {
          this.showModalTimeSession = true;
          this.authService.status = AuthStatus.nonAuthenticated;
        }
      }
    }, 1000);
  }

  goToLogin(): void {
    this.showModalTimeSession = false;
    this.router.navigate([PATHS.LOGIN]);
  }

  goBack(): void {
    this.showModalTimerSession = false;
    this.router.navigate([PATHS.LANDING]);
  }

  validateTimerSession({expire, startDate}) {
    let timeElapsed = (new Date().getTime() - new Date(startDate).getTime()) / 1000;
    if (timeElapsed >= expire) return;
    let distance = (expire  - timeElapsed) * 1000;

    this.timerInterval = setInterval(() => {
      let leftTime = this.utilService.getSimpleTimer(distance);
      distance = distance - 1000;
      if (leftTime === false) {
        sessionStorage.removeItem('timer_session_started');
        sessionStorage.removeItem('timer_session_expire');
        clearInterval(this.timerInterval);
        location.reload();
      } else {
        this.leftTime = leftTime;
      }
    }, 1000);
  }

  @HostListener('window:unload')
  doSomethingAfter() {
    const sessionId = this.localStorageService.getStorage('token')?.session?.sessionId;
    this.localStorageService.setStorage('sessionId', sessionId);
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.checkHiddenDocument();
  }

  checkHiddenDocument() {
    if (document.hidden) {
      clearInterval(this.timerInterval);
    } else {
      const startDate = sessionStorage.getItem('timer_session_started') || null;
      const expire = sessionStorage.getItem('timer_session_expire') || null;
      if (startDate && expire) this.validateTimerSession({ expire, startDate });
    }
  }
}
