import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoaderData } from 'src/app/shared/models/model'
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private spinner$ = new BehaviorSubject<LoaderData>({ status: '' });

  getSpinnerObserver(): Observable<LoaderData> {
    return this.spinner$.asObservable();
  }

  requestStarted(text?: string, imgSrc?: string) {
    this.spinner$.next({
      status: 'start',
      text: text || 'Cargando...', // texto por defecto
      imgSrc: imgSrc || '/assets/images/loader.svg' // imagen por defecto
    });
  }

  requestEnded() {
    this.spinner$.next({ status: 'stop' });
  }
}
