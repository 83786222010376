import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TYPE_SEND_RECEPTOR } from 'src/app/core/constants';
import { GtmEvent, Operation } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Currency } from 'src/app/shared/models/model';

@Component({
  selector: 'app-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class ElementComponent {

  @Input() element: Operation;
  @Output() emitData = new EventEmitter();

  private gtmService = inject(GtmService);
  private localStorageService = inject(LocalStorageService);

  Currency = Currency

  sendData(element: Operation): void {
    this.emitData.emit(element);

    const gtmEvent: GtmEvent = { 
      event: 'virtuaEvent',
      accion: 'Home_EditarEnviar',
      pantalla: 'Home',
      email: this.localStorageService.getStorage('customer')?.email || '',
    };
    this.gtmService.sendEvent(gtmEvent);
  }

  formatDate(date): string {
    try {
      const split = date.split('T')[0].split('-');
      return `${split[2]}/${split[1]}/${split[0]}`;
    } catch (error) {
      return '';
    }
  }

  getReceiverName(receiverFirstName: string, receiverLastname: string): string {
    let name = `${ receiverFirstName } ${ receiverLastname }`;
    if ((receiverFirstName.length + receiverLastname.length) > 23) {
      name = `${name.substring(0, 22)}...`;
    }
    return name;
  }

  getTypeSend() {
    return this.element.typeSendReceptor === TYPE_SEND_RECEPTOR.YAPE ? TYPE_SEND_RECEPTOR.YAPE.toLowerCase() : 'bcp';
  }
}
