import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-currency-conversion-calculator',
  templateUrl: './currency-conversion-calculator.component.html',
  styleUrls: ['./currency-conversion-calculator.component.scss']
})
export class CurrencyConversionCalculatorComponent {
  selectedCurrency: string = "PEN";
  firstInputValue = null;
  secondInputValue = null;
  exchangeRate=null;
  commission: number;
  objExchangeRate: 
  { exchangeRate: number; 
    exchangeRateFinal: number; 
    hasOperationValid: boolean; 
  };
  hasOperationValid : boolean = true
  showcomplete : boolean = false

  @ViewChild('firstInput') firstInput: ElementRef;
  @ViewChild('secondInput') secondInput: ElementRef;

  private cdr = inject(ChangeDetectorRef)
  private router = inject(Router)
  private gtmService = inject(GtmService)
  private mainService = inject(MainService)

  @Output() showTariffModalEvent = new EventEmitter<void>();


  constructor(
    private renderer: Renderer2
  ) { }

  /**
   * Validates that the inputted character is valid (character is not letter, and is not a repeated separator)
   * @param inputElement 
   * @returns the input value after it was validated
   */
  validateInputValue(inputElement: HTMLInputElement): string {
    // Characters that are allow to be write in the field
    const allowedCharacters = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '.',
      ','
    ]

    // Separator characters
    const separators = [',', '.']

    let value = inputElement.value

    // Get last typed character
    let char = value.charAt(value.length - 1)

    let isCharAllowed = allowedCharacters.includes(char) //Check if character is allowed
    let isSeparator = separators.includes(char) // Check if character is separator
    let hasSeparator = value.substring(0, value.length - 1).includes('.') || value.substring(0, inputElement.value.length - 1).includes(',') //check if field value has separator


    // if character is separator and input field has separator, return value without last inputed character
    if (isSeparator && hasSeparator) return value.substring(0, value.length - 1)

    // if character is not allowed, return value without last inputed character
    if (!isCharAllowed) {
      return value.substring(0, value.length - 1)
    }

    return value
  }


  /** Formats input by replacing commas (,) with dot (.)
   * 
   * @param value - The input value to be formatted.
   * @returns The formatted value.
   */
  formatInputValue(inputElement: HTMLInputElement): string {
    let value = inputElement.value

    const parts = value.split(/[.,]/)

    if (parts.length >= 2) {
      value = `${parts[0]}.${parts[1].substring(0, 2)}`;
    }

    return value
  }

  onSelectedCurrencyChange(event: any) {
    const inputElement = event.target as HTMLInputElement
    this.selectedCurrency = inputElement.value;
    this.secondInputValue = (this.firstInputValue === '' || this.firstInputValue === '.') ? '0.00' : (parseFloat(this.firstInputValue) * (this.selectedCurrency === 'PEN' ? this.exchangeRate : 1)).toFixed(2)
    this.secondInput.nativeElement.value = this.secondInputValue
  }

  onFirstInputChange(event: any) {
    const inputElement = event.target as HTMLInputElement

    this.firstInputValue = this.formatInputValue(inputElement)
    inputElement.value = this.firstInputValue

    this.firstInputValue = this.validateInputValue(inputElement)
    inputElement.value = this.firstInputValue


    if (this.firstInputValue === '') {
      this.secondInputValue = ''
    } else {
      this.secondInputValue = this.firstInputValue === '.' ? '0.00' : (parseFloat(this.firstInputValue) * (this.selectedCurrency === 'PEN' ? this.exchangeRate : 1)).toFixed(2)
    }

  }

  onSecondInputChange(event: any) {
    const inputElement = event.target as HTMLInputElement

    this.secondInputValue = this.formatInputValue(inputElement)
    inputElement.value = this.secondInputValue

    this.secondInputValue = this.validateInputValue(inputElement)
    inputElement.value = this.secondInputValue

    if (this.secondInputValue === '') {
      this.firstInputValue = ''
    } else {
      this.firstInputValue = this.secondInputValue === '.' ? '0.00' : (parseFloat(this.secondInputValue) / (this.selectedCurrency === 'PEN' ? this.exchangeRate : 1)).toFixed(2)
    }
  }

  calculateCommission() {
    let commission = 0;
    if (parseFloat(this.firstInputValue) > 0)
      commission = 3.99;
    if (parseFloat(this.firstInputValue) >= 100)
      commission = 5.50;
    if (parseFloat(this.firstInputValue) >= 200)
      commission = 7.50;
    if (parseFloat(this.firstInputValue) >= 501)
      commission = 9.0;
    if(parseFloat(this.firstInputValue) > 970 || this.firstInputValue == null){
      commission = 0;
    }
    return commission;
  }

  setInitialInputValues() {
    this.secondInputValue = (Number(this.firstInputValue) * this.exchangeRate).toFixed(2)
    this.cdr.detectChanges();
    setTimeout(() => {
      this.renderer.selectRootElement(this.firstInput.nativeElement).focus();
    }, 200);
  }

  formatInputValueOnBlur(event: FocusEvent): string {
    const inputElement = event.target as HTMLInputElement
    inputElement.blur();

    let value = inputElement.value

    if (value === '' || value === '.') return ''

    let containsSeparator = value.includes('.') || value.includes(',')

    if (!containsSeparator) return `${value}.00`

    let parts = value.split(/[.,]/)

    let integers = parts[0] || '0'
    let decimals = parts[1] || '00'

    if (decimals.length === 1) decimals = `${decimals}0`

    return `${integers}.${decimals}`
  }

  onFirstInputBlur(event: FocusEvent) {
    this.firstInputValue = this.formatInputValueOnBlur(event)
  }

  onSecondInputBlur(event: FocusEvent) {
    this.secondInputValue = this.formatInputValueOnBlur(event)
  }

  goToRegister() {
    if(!this.showcomplete)return;

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Enviar_Calculadora',
      pantalla: 'Calculadora'
    }

    this.gtmService.sendEvent(gtmEvent)

    this.router.navigate([PATHS.REGISTER])
  }

  showTariffModal(){
    if(!this.showcomplete)return;
    this.showTariffModalEvent.emit()
  }

  changeDni(event : any){
    this.showcomplete = event.show;
    this.hasOperationValid= event.res?.hasOperationValid;
    this.exchangeRate = event.res?.hasOperationValid ?  event.res?.exchangeRate : event.res?.exchangeRateFinal;
    if(!this.showcomplete){
      this.cleanCalculator();
    }
    this.setInitialInputValues();
  }

  cleanCalculator(){
    this.firstInputValue = null;
    this.secondInputValue = null;
    this.hasOperationValid= true;
    this.exchangeRate = null
  }

  goToLogin(): void {
    this.router.navigate([PATHS.LOGIN]);
  }
} 
