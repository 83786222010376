<div class="md-contain">
    <div class="md-input" *ngIf="control">
        <input [type]="properties.type"
            [formControl]="control"
            [id]="properties?.inputId"
            [maxlength]="properties?.maxlength"
            [placeholder]="properties?.placeholder"
            [ngClass]="{'invalid': control.invalid && submitted}">
        <label
            [for]="properties?.inputId"
            [class.required]="isRequired"
            [ngClass]="{'float-label': true}"
            [innerHTML]="properties?.label"
            style="background: white;">
            
        </label>
    </div>
    <app-md-validation-errors [inputName]="properties?.inputId" [customErrorMessage]="properties.customErrorMessage" [errors]="control.errors" *ngIf="submitted"></app-md-validation-errors>
</div>