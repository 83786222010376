import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { message } from '../referral-banner/constants';

@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrls: ['./referral-modal.component.scss']
})
export class ReferralModalComponent {

  @Input() showModal = false;
  @Input() customerReferralCode: string;
  @Output() closeModalEvent = new EventEmitter<boolean>();

  private gtmService = inject(GtmService);
  private localStorageService = inject(LocalStorageService);

  closeModal() {
    this.closeModalEvent.emit(false);
  }

  shareReferralCode() {
    const encodeURIMessage = encodeURIComponent(message.concat(this.customerReferralCode));
    const pathLinkGenerated = `https://wa.me/?text=${encodeURIMessage}`;
    const link = document.createElement('a');
    link.href = pathLinkGenerated;
    link.target = '_blank';
    link.click();
    this.sendGTMEvent()
  }
  

  sendGTMEvent(){
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Ref_Compartir_popup',
      pantalla: 'Envio paso 1',
      email: this.localStorageService.getStorage('customer')?.email || '',
    }

    this.gtmService.sendEvent(gtmEvent)
  }

}
