import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";
import { LocalStorageService } from "src/app/core/services/local-storage.service";

@Injectable({
    providedIn: 'root'
})

export class UtilsManagement {

    constructor(
        private localStorageService: LocalStorageService
    ) {}

    getEmail(): string {
        const { idToken } = this.localStorageService.getStorage('token');
        const decoded: any = jwt_decode(idToken);
    
        return decoded.name;
    }
}