import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  show = false;

  private loaderService = inject(LoaderService);
  private cdRef = inject(ChangeDetectorRef);

  loaderText = 'Cargando...';
  loaderImage = '/assets/images/loader.svg';

  ngOnInit(): void {
    this.init();
  }
  
  init() {
    this.loaderService.getSpinnerObserver().subscribe((data) => {
      this.show = (data.status === 'start');
      this.loaderText = data.text || this.loaderText; // Mantener texto actual si no hay nuevo
      this.loaderImage = data.imgSrc || this.loaderImage; // Mantener imagen actual si no hay nueva
      this.cdRef.detectChanges();
    });
  }

}
