<!-- <div class="loader" *ngIf="show">
    <div class="content">
        <img src="/assets/images/loader.svg" alt="">
        <p>Cargando...</p>
    </div>
</div> -->

<div class="loader" *ngIf="show">
    <div class="content">
      <img [src]="loaderImage" alt="">
      <p>{{ loaderText }}</p>
    </div>
</div>