import { Pipe, PipeTransform } from '@angular/core';
import { TYPE_SEND_RECEPTOR } from 'src/app/core/constants';

@Pipe({
  name: 'accountMask'
})
export class AccountMaskPipe implements PipeTransform {

  private readonly YAPE_PREFIX = 'Yape';
  private readonly BCP_PREFIX = 'BCP';
  private readonly YAPE_PATTERN = /(\*{6})(\d{3})$/;
  private readonly BCP_PATTERN = /(\*{11})(\d{1})(\d{2})$/;

  transform(value: string, sendType: string, hasPrefix: boolean = true): string {
    const prefix = sendType === TYPE_SEND_RECEPTOR.YAPE ? this.YAPE_PREFIX : this.BCP_PREFIX;
    return value ? this.formatAccount(value, prefix, hasPrefix, sendType) : '';
  }

  private formatAccount(account: string, prefix: string, hasPrefix: boolean, sendType: string): string {
    let match;
    let formattedAccount;

    if (sendType === TYPE_SEND_RECEPTOR.YAPE) {
      //match = account.match(this.YAPE_PATTERN)
      // formattedAccount = match ? `+51 *** *** ${match[2]}` : account;
      match = account.match(this.YAPE_PATTERN) || account.match(/^\d{9}$/);
      formattedAccount = match ? `+51 *** *** ${account.slice(-3)}` : account;
    } else {
      // match = account.match(this.BCP_PATTERN);
      // formattedAccount = match ? `*** ******** ${match[2]} ${match[3]}` : account;
      match = account.match(this.BCP_PATTERN) || account.match(/^\d{14}$/);
      formattedAccount = match ? `*** ******** ${account.slice(-3, -2)} ${account.slice(-2)}` : account;
    }

    return hasPrefix ? `${prefix} ${formattedAccount}` : formattedAccount;
  }
}
