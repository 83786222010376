import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { MANDADITO_STEPS, ModalYapeTypes } from 'src/app/core/constants';
import { Modal,OPERATIONS, GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { ICustomerData } from '../../models/model';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { DataService } from 'src/app/core/services/data.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
@Component({
  selector: 'app-shared-modals-yape',
  templateUrl: './shared-modals-yape.component.html',
  styleUrls: ['./shared-modals-yape.component.scss']
})
export class SharedModalsYapeComponent implements OnChanges {

  private gtmService = inject(GtmService);
  @Input() modal: Modal;
  @Input() customer: ICustomerData;
  @Output() closeModalEvent = new EventEmitter<any>()
  modalName = null;
  modals = ModalYapeTypes;
  @Output()
  backStep = new EventEmitter<any>();
  @Input() showModalYape;
  @Input() isRepeatingMandadito;
  showModal: boolean;
  @Input() presetAmount;
  @Input() activeHome = false;

  private router = inject(Router);
  private dataService = inject(DataService);
  private localStorageService = inject(LocalStorageService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modal'] && changes['modal'].firstChange === false) {
      this.updateModalData(changes['modal'].currentValue);
    }
  }
  updateModalData(modal: Modal) {
    this.showModal = this.showModalYape;
    this.modalName = modal.name;
    this.gtmService.sendEvent(modal.gtm);
    
  }

  goToReceptorYape(valueReset: boolean): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: this.modalName === this.modals.GENERIC ? 'Yape_unhappy_nodisponible_otro​' : 'Yape_unhappy_datos_revisar',
      pantalla:  this.modalName === this.modals.GENERIC ? 'Unhappy No disponible' : 'Unhappy datos​',
      email: this.localStorageService.getStorage('customer')?.email || ''
    };
    this.gtmService.sendEvent(event);
    this.showModal = false;
    this.backStep.emit({step:MANDADITO_STEPS.YAPE, resetReceiver: valueReset});
  }

  goToShippingBCP(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Yape_unhappy_caida_cuenta​' ,
      pantalla: 'Unhappy caida​',
      email: this.localStorageService.getStorage('customer')?.email || ''
    };
    this.gtmService.sendEvent(event);
    this.showModal = false;
    this.backStep.emit({step:MANDADITO_STEPS.SHIPPINGMETHOD , resetReceiver: true});
  }

  goToHome() {
    const actionMap: { [key: string]: string } = {
      [this.modals.GENERIC]: this.isRepeatingMandadito
        ? 'Yape_unhappy_nodisponible_repetir_home' 
        : 'Yape_unhappy_nodisponible_home',
      [this.modals.NO_RESPONSE]: this.isRepeatingMandadito
      ?'Yape_unhappy_caida_home'
      :'Yape_unhappy_caida_repetir_home',
    };
    
    const screenMap: { [key: string]: string } = {
      [this.modals.GENERIC]:'Unhappy No disponible',
      [this.modals.NO_RESPONSE]: 'Unhappy caida',
    };
    const currentModal = this.modalName;
    
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: actionMap[currentModal] || actionMap[this.modals.GENERIC], 
      pantalla: screenMap[currentModal] || actionMap[this.modals.GENERIC],
      email: this.localStorageService.getStorage('customer')?.email || ''
    };
    this.gtmService.sendEvent(event);
    this.showModal = false;
    this.backStep.emit({ step: MANDADITO_STEPS.AMOUNT, resetReceiver: true });
  }

  goBack(){
    this.presetAmount = null;
    this.activeHome = true;
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.RESET_MANDADITO, data: null });
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

}
