// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlBase: 'https://mandadito-dev-api.innovacxion.com/api/',
  ipApi: 'https://ipapi.co/json/',
  ipStack: 'https://api.ipstack.com/check?access_key=f11950c2aded6a3e193d2c1ed072f668', // Change ipInfo for ipStack
  urlTermsConditions: 'https://staceu2mandd01.blob.core.windows.net/public-documents/BCPMandadito-TOS-041024.pdf',
  urlTermsConditionsPrivacyPolicy:'https://staceu2mandd01.blob.core.windows.net/public-documents/BCPMandadito-TOS-041024+pp.pdf',
  inMaintenance: false,
  gtmID: 'GTM-WJJMPSSC',
  ocpApimSubscriptionKey: 'd1182aefa56744c0946c3176406fd3ad',
  sentryEnvironment:'dev',
  trustedIps: ['2001:1388:18:8337:117c:b6e7:f95e:67e3', '163.116.229.32', '163.116.229.23'],
  recaptcha: {
    siteKey: '6LcGb2EpAAAAAA3P-rgzZT9DoQDA_aFf5Z2FuC7n',
  },
  urlBaseBlob: 'https://staceu2mandd01.blob.core.windows.net',
  otp: {
    key: 'bb7a1ef3a6e11085f34613b17fb180bd-8be8ce04-9ccb-4dcf-be9a-6b38e2530669',
    uri: 'https://43w9q1.api.infobip.com/sms/2/text/advanced'
  },
  paypal: 'https://www.paypal.com/sdk/js?client-id=sb&disable-funding=paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo&currency=USD'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
