import { HttpErrorResponse } from "@angular/common/http";

export interface ICarouselHeadItem {
  id?: number;
  imageLeft?: string;
  imageRight?: string;
  title: string;
  hasModal: boolean;
  marginLeft?: number;
}

export interface ICarouselItem {
  id?: number;
  image?: string;
  images?: Images;
  text: string;
  title: string;
  subtitle?: string;
  link?: string;
  order?: number;
  marginLeft?: number;
}

interface Images {
  responsive: string;
  desktop: string;
}

export interface IBreadcrumbItem {
  text: string
  textBack: string
  route: string
  itemAccessibleConfig?: any
}

export interface ICustomerData {
  firstName: string;
  secondName: string;
  firstLastname: string;
  secondLastname: string;
  documentNumber: string;
  email: string;
  phonePrefix: string;
  phoneNumber: string;
  customerState: string;
  hasOperationsCompleted: boolean;
  totalAmount: number;
  referralCode?: string;
  referralCodeUsed?: string;
  hasOperationsValid?: boolean;
  viewNewHome?: boolean;
  exchangeRate?: number;
  exchangeRateFinal?: number;
}

export interface IWithinAccessTime {
  isWithinAccessTime: boolean
}

export interface ILoginHttpError {
  origin: string
  error: HttpErrorResponse
}

export const DocumentTypes = {
  DNI: 'DNI',
  DriverLicense: 'DL'
}

export const Currency = {
  PEN: {
    code: 'PEN',
    symbol: 'S/'
  },
  USD: {
    code: 'USD',
    symbol: '$'
  },
}
export interface LoaderData {
  status: string;
  text?: string;
  imgSrc?: string;
}
